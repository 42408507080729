
export class JsUtils {

    jsPathTwigArray(url, arrayItem) {
        s
        let i = 0;
        let varPath = 'js';

        for (var item in arrayItem) {

            i++;
            varPath = 'js' + i;

            url = url.replace(varPath, arrayItem[item]);

        }

        return url;
    }

    jsPathTwig(url, jsVar) {
        return url.replace("jsVar", jsVar);
    }

    // Modal directly at the base of template, extra validation for actions
    validationModal(text = '', url = '') {
        const validationModal = document.getElementById('validation_modal')
        if (validationModal) {
            const modalText = validationModal.querySelector('#validation_modal_text')
            if (modalText) {
                modalText.innerHTML = text
            }
            const modalHref = validationModal.querySelector('#validation_modal_href')
            if (modalHref) {
                modalHref.href = url
            }
            validationModal.classList.add('flex')
            validationModal.classList.remove('hidden')
        }
    }

    closeValidationModal() {
        const validationModal = document.getElementById('validation_modal')
        validationModal.classList.add('hidden')
        validationModal.classList.remove('flex')
        validationModal.querySelector('#validation_modal_text').innerHTML = ''
        validationModal.querySelector('#validation_modal_href').href = '#'
    }

    ajaxForm({ form, url, submitBtn, textBtn, modal, prefix, onSuccess, content = 'json' }) {
        // get from by id sent as param
        const formToSubmit = document.getElementById(form)
        const btn = document.getElementById(submitBtn)
        const thisClass = this
        // add a submit listerner on the form
        formToSubmit.addEventListener('submit', function (e) {

            // prevent submiting
            e.preventDefault()
            // fill form data from form widgets

            let formData = new FormData(formToSubmit);

            thisClass.btnOnLoad(btn, textBtn);
            let options = {
                method: 'POST',
                body: formData
            }
            // Post form with fetch
            fetch(url, options).then((response) => {
                if (response.ok) {
                    if (content === 'html') {
                        return response.text()
                    } else {
                        return response.json()
                    }

                } else {
                    throw new Error()
                }
            })
                .then((result) => {
                    // error throw by the controller, loop thru inputs to display errors
                    if (result['status'] && result['status'] === 'errors') {
                        // clean previous form errors
                        const inputsOnError = formToSubmit.querySelectorAll('.form-error')
                        if (inputsOnError) {
                            // clean previous erro (class and tooltips)
                            Array.from(inputsOnError).map((inputWrapper) => {
                                inputWrapper.classList.remove('form-error')
                                inputWrapper.dataset.bsToggle = "no-tooltip"
                                inputWrapper.dataset.title = ""
                            })
                        }
                        // Loop thru errors then by the controllers and display them on inputs
                        for (const [key, value] of Object.entries(result['errors'])) {
                            let input = formToSubmit.querySelector('input[name="' + prefix + '[' + key + ']"]')
                            if (input) {
                                input.parentNode.classList.add('form-error')
                                input.parentNode.dataset.bsToggle = "tooltip"
                                input.parentNode.dataset.title = value[0]
                            } else {
                                // Specific for multiple inputs like password (password verification)
                                input = formToSubmit.querySelector('input[name="' + prefix + '[' + key + '][first]"]')
                                input.parentNode.classList.add('form-error')
                                input.parentNode.dataset.bsToggle = "tooltip"
                                input.parentNode.dataset.title = value['first'][0]
                            }
                            thisClass.tooltips()
                        }

                    } else if (result['status'] && result['status'] == 'error') {
                        // Display general error to the form
                        let errorContainer = formToSubmit.querySelector('#form_single_error_container');
                        errorContainer.classList.add('form-error')
                        errorContainer.innerHTML = result['error']
                    } else {
                        thisClass.destroyModal(modal)
                        if (typeof (onSuccess) != "undefined") {
                            onSuccess(result);
                        } else {
                            location.reload();
                        }
                    }
                    thisClass.btnOnLoad(btn, textBtn);
                })
                .catch((err) => {
                    // error throw by the server
                    // Display general error to the form
                    let errorContainer = formToSubmit.querySelector('#form_single_error_container');
                    errorContainer.classList.add('form_error')
                    errorContainer.innerHTML = 'Une erreur est survenue'
                })

        })
    }

    btnOnLoad(btn, textBtn) {
        // check if the button has a spinner as content
        const spinnerChild = btn.querySelector('.fa-spinner')
        // if the btn has a spinner, we replace the html content by the original content
        if (spinnerChild) {
            btn.disabled = false
            btn.textContent = textBtn
        } else {
            // Replace btn content by spinner
            btn.disabled = true
            btn.textContent = ''
            btn.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
        }
    }

    btnOnLoadReplaceHtml(btn, textBtn) {
        // check if the button has a spinner as content
        const spinnerChild = btn.querySelector('.fa-spinner')
        // if the btn has a spinner, we replace the html content by the original content
        if (spinnerChild) {
            btn.disabled = false
            btn.innerHTML = textBtn
        } else {
            // Replace btn content by spinner
            btn.disabled = true
            btn.innerHTML = '<i class="fas fa-spinner fa-spin"></i>'
        }
    }

    ajaxModal(element, modalId, url, htmlBtn = false, elementDataUrl = "", useIdOrClass = "#") {
        // htmlBtn -> get the content of the button ex with icone, not just the text
        // elementDataUrl retrive a data from the element to send it as param (replace "js_var")
        // ex path {{"foo", {'id': jsVar}}} -> jsVar  = element data-id
        // as it can be classes or only an id, querySelector all to return NodeList
        const thisClass = this
        let clikableElements = document.querySelectorAll(useIdOrClass + element)
        if (clikableElements) {
            // Loop thru NodeList, even if it's only one id
            Array.from(clikableElements).map((clikableElement) => {
                // add click event on element
                clikableElement.addEventListener('click', function () {
                    let textBtn = clikableElement.textContent
                    let newUrl = url

                    if (htmlBtn) {
                        textBtn = clikableElement.innerHTML
                        thisClass.btnOnLoadReplaceHtml(clikableElement, textBtn);
                    } else {
                        thisClass.btnOnLoad(clikableElement, textBtn);
                    }

                    // Update url based on data send as param (ex:id) for data-id
                    if (elementDataUrl.length !== 0) {
                        if (Object.hasOwn(clikableElement.dataset, elementDataUrl)) {
                            let value = clikableElement.dataset[elementDataUrl];
                            newUrl = url.replace('jsVar', value);
                        } else {
                            console.log('missing datasets')
                        }

                    }

                    // receive the modal html with an ajax call
                    fetch(newUrl).then((response) => {
                        if (response.ok) {
                            return response.text()
                        } else {
                            throw new Error()
                        }
                    })
                        .then((result) => {
                            if (htmlBtn) {
                                thisClass.btnOnLoadReplaceHtml(clikableElement, textBtn);
                            } else {
                                thisClass.btnOnLoad(clikableElement, textBtn);
                            }
                            // Add the modal at the end of the body
                            // create range generate html and scripts
                            const divFragment = document.createRange().createContextualFragment(result);
                            document.body.append(divFragment);

                            const modal = document.getElementById(modalId)

                            // set modal to visible
                            modal.classList.add('flex')
                            modal.classList.remove('hidden')

                        })
                        .catch((err) => {
                            // new Toaster({
                            //     content: 'Une erreur est survenue lors du chargement'
                            // })
                            thisClass.btnOnLoad(clikableElement, textBtn);
                        })

                })
            })
        }
    }

    destroyModal(modalId) {
        if (document.getElementById(modalId)) {
            document.getElementById(modalId).remove()
        }
        if (document.getElementById('js_script_' + modalId)) {
            document.getElementById('js_script_' + modalId).remove()
        }

        if (document.querySelectorAll('.js_modal_script')) {
            Array.from(document.querySelectorAll('.js_modal_script')).map((scriptModal) => {
                scriptModal.remove()
            })
        }
    }

    fetchModal({ url, modalId, clickedElement, functionOnDone = null }) {
        // receive the modal html with an ajax call
        fetch(url).then((response) => {
            if (response.ok) {
                return response.text()
            } else {
                throw new Error()
            }
        })
            .then((result) => {
                // Add the modal at the end of the body
                // create range generate html and scripts
                const divFragment = document.createRange().createContextualFragment(result);
                document.body.append(divFragment);

                const modal = document.getElementById(modalId)

                // set modal to visible
                modal.classList.add('flex')
                modal.classList.remove('hidden')

                clickedElement.classList.remove('disabled');
                functionOnDone && functionOnDone()
            })
            .catch((err) => {
                // new Toaster({
                //     content: 'Une erreur est survenue lors du chargement de la sélection'
                // })
                clickedElement.classList.remove('disabled');
                functionOnDone && functionOnDone()
            })
    }

    loadAjaxTemplate(element, url) {
        document.querySelector(element).innerHTML = '<i class="fas fa-spinner fa-spin ml-1"></i>'
        fetch(url).then((response) => {
            if (response.ok) {
                return response.text()
            } else {
                throw new Error()
            }
        })
            .then((result) => {
                document.querySelector(element).innerHTML = ""
                // used to insert html and scripts
                const divFragment = document.createRange().createContextualFragment(result);
                document.querySelector(element).append(divFragment);
            })
            .catch((err) => {
                // new Toaster({
                //     content: 'une erreur est survenue dans le chargement de l\'élément'
                // })
            })
    }

    shadeColor(color, percent) {
        var R = parseInt(color.substring(1, 3), 16);
        var G = parseInt(color.substring(3, 5), 16);
        var B = parseInt(color.substring(5, 7), 16);

        R = parseInt(R * (100 + percent) / 100);
        G = parseInt(G * (100 + percent) / 100);
        B = parseInt(B * (100 + percent) / 100);

        R = (R < 255) ? R : 255;
        G = (G < 255) ? G : 255;
        B = (B < 255) ? B : 255;

        var RR = ((R.toString(16).length == 1) ? "0" + R.toString(16) : R.toString(16));
        var GG = ((G.toString(16).length == 1) ? "0" + G.toString(16) : G.toString(16));
        var BB = ((B.toString(16).length == 1) ? "0" + B.toString(16) : B.toString(16));

        return "#" + RR + GG + BB;
    }

    contrastColor(color) {
        const hex = color.replace('#', '');
        const c_r = parseInt(hex.substr(0, 2), 16);
        const c_g = parseInt(hex.substr(2, 2), 16);
        const c_b = parseInt(hex.substr(4, 2), 16);
        const brightness = ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
        const textColour = (brightness > 180) ? 'black' : 'white'
        return textColour
    }

    setCookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = "expires=" + d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;secure=true;"
    }

    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }
    tooltips() {
        let tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
        Array.from(tooltipTriggerList).map(function (tooltipTriggerEl) {
            const previousTooltip = tooltipTriggerEl.querySelector('.js-tooltip-element')
            if (previousTooltip) {
                previousTooltip.remove()
            }
            // Create tooltip content
            let tooltip = document.createElement('div')
            tooltip.classList.add('absolute', 'top-0', 'left-0', 'w-max-content', 'hidden', 'flex-col', 'items-start', 'z-2', 'js-tooltip-element')

            // title
            let tooltipText = document.createElement('div')
            tooltipText.classList.add('text-white', 'bg-dark', 'w-full', 'p-0.5', 'radius-0.25', 'text-sm')
            tooltipText.innerHTML = tooltipTriggerEl.dataset.title
            tooltip.appendChild(tooltipText)

            // content
            let tooltipContent = null
            if (Object.hasOwn(tooltipTriggerEl.dataset, 'content')) {
                tooltipContent = document.createElement('div')
                tooltipContent.classList.add('text-white', 'bg-dark', 'w-full', 'p-0.5', 'radius-0.25', 'text-sm')
                tooltipContent.innerHTML = tooltipTriggerEl.dataset.content
                tooltip.appendChild(tooltipContent)
            }

            let tooltipIArrow = document.createElement('i')
            tooltipIArrow.classList.add('fas', 'fa-sort-down', 'text-dark', 'text-lg', '-mt-1', 'ml-0.5')
            tooltip.appendChild(tooltipIArrow)

            tooltipTriggerEl.appendChild(tooltip)

            // Show mouseOver
            tooltipTriggerEl.addEventListener("mouseover", (event) => {
                tooltip.classList.add('flex')
                tooltip.classList.remove('hidden')

                // Adapt the position of the tooltip based on element hovering position
                if (tooltipText) {
                    if (tooltipContent) {
                        // remove the small corner visualisation created by border-radius
                        tooltipContent.style.marginTop = '-0.25rem'
                        // set tooltip top
                        tooltip.style.top = -(tooltipText.offsetHeight + tooltipContent.offsetHeight) + 'px'
                    } else {
                        // set tooltip top
                        tooltip.style.top = -(tooltipText.offsetHeight) + 'px'
                    }
                }
            });
            // hide mouseout
            tooltipTriggerEl.addEventListener("mouseout", (event) => {
                tooltip.classList.add('hidden')
                tooltip.classList.remove('flex')
            });
        })
    }
    navTabs() {
        const navLinks = document.querySelectorAll("[data-bs-toggle='tab']")
        if (navLinks) {
            Array.from(navLinks).map((navLink) => {
                navLink.addEventListener('click', () => {
                    // remove previous active link
                    Array.from(navLinks).map((prevNavLink) => {
                        prevNavLink.classList.remove('active')
                    })
                    const activesPane = document.querySelectorAll('.tab-pane.active')
                    if (activesPane) {
                        Array.from(activesPane).map((pane) => {
                            pane.classList.remove('active')
                        })
                    }

                    // switch visible active nav link (text-bold)
                    navLink.classList.add('active')

                    // get the pan to show as set in data-b-target
                    const panToDisplay = navLink.dataset.bsTarget
                    if (panToDisplay) {
                        if (document.querySelector(panToDisplay)) {
                            document.querySelector(panToDisplay).classList.add('active')
                        }
                    }
                })
            })
        }
    }

    accordions() {
        const accordionsHeader = document.querySelectorAll("[data-bs-toggle='collapse']")
        if (accordionsHeader) {
            Array.from(accordionsHeader).map((accordionHeader) => {
                accordionHeader.addEventListener('click', () => {
                    const targetToToggle = accordionHeader.dataset.bsTarget
                    document.querySelector(targetToToggle).classList.toggle('hidden')
                    const icon = accordionHeader.querySelector('.js_toggle_icon')
                    if (icon) {
                        icon.classList.toggle('fa-chevron-down')
                        icon.classList.toggle('fa-chevron-up')
                    }
                })
            })
        }
    }

    animatedCheckBox(classNames, style = "") {
        const checkBoxs = document.querySelectorAll(classNames)
        let hasError = false
        if (checkBoxs.length > 0) {
            Array.from(checkBoxs).map((checkBox) => {
                // the real checkbox input
                const input = checkBox.querySelector('input')

                if (input) {
                    // 2 kind of styles with different heigh and width
                    if (style === 'sm') {
                        checkBox.classList.add('animated-checkbox-sm')
                    } else {
                        checkBox.classList.add('animated-checkbox')
                    }
                    input.classList.add('hidden')

                    // the animated check box
                    const styledCheckbox = document.createElement('span')
                    styledCheckbox.classList.add('checkbox-content')
                    input.checked && styledCheckbox.classList.add('is-checked')
                    checkBox.appendChild(styledCheckbox)

                    checkBox.addEventListener('click', () => {
                        input.checked = !input.checked
                        styledCheckbox.classList.toggle('is-checked')
                    })
                } else {
                    hasError = true
                }
            })
        } else {
            hasError = true
        }

        // check if has render for react
        return { hasError }
    }

}